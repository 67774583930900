
import Vue from "vue";
// import Header from "@/components/Header.vue"; // import Footer from "@/components/Footer.vue";
// import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "DynamicPage",
  components: {
    // Header
    // Footer
  },
  mounted() {
    const ct = document.querySelector(".main-container") as any,
      t = function(e: any) {
        //@ts-ignore
        const vm = this;
        const k = e.keyCode;
        if (k == 13 || k == 32 || !k) {
          const c = "running",
            cl = vm.classList;
          if (!cl.contains(c)) {
            cl.add(c);
            setTimeout(function() {
              cl.remove(c);
            }, 1e4);
          }
        }
      };
    ct.addEventListener("click", t);
    ct.addEventListener("keydown", t);
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    }
  }
});
